import React, { Component } from 'react';
import MyTools from './MyTools'
import Language from './Language'
import Settings from './Settings'

export class Missing extends Component {
  props: any;
  render() {
    return (
      <div className={MyTools.GetDivClassName(this.props.action, this.props.isLight)}>
          <div className="ib1">
            <div className="flexAddress">
            <div className="Action">
                <h1>
                <a href={MyTools.GetUrl(this.props.action, this.props.startDate, this.props.endDate, this.props.carid)}>{this.props.action}</a>
                </h1>
            </div>
            <div>
                <h4>{this.props.startAddress}</h4>
            </div>
            </div>
            <div  className="flexColumns">
            <div >
                {Language.t("Datum")}: {MyTools.FormatUnixTimestamp(this.props.startDate)}
                <br />
                {Language.t("km Stand")}: {this.GetOdometer()} {Settings.km_miles()}
                <br />
            </div>
            <div>{Language.t("Missing")}: {this.GetDistance()} {Settings.km_miles()}</div>
            <div></div>
            </div>
        </div>
      </div>
    );
  }

  GetOdometer()
  {
    let x = Settings.Distance(this.props.odometer);
    let y = Math.round(x * 10) / 10;
    return y;
  }

  GetDistance()
  {
    let x = Settings.Distance(this.props.distance);
    let y = Math.round(x * 10) / 10;
    return y;
  }
}