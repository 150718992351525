import React, { Component } from 'react';
import MyTools from './MyTools'
import Language from './Language'
import Units from './Settings'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Settings from './Settings';

export class Parking extends Component {
  props: any;

  render() {
    return (
      <div className={MyTools.GetDivClassName(this.props.action, this.props.isLight)}>
        <div className="ib1">
          <div className="flexAddress">
            <div className="Action">
              <h1>
                <a href={MyTools.GetUrl(this.props.action, this.props.startDate, this.props.endDate, this.props.carid)} title={Language.t("Verbrauch")}>{Language.t(this.props.action)}</a>
              </h1>
            </div>
            <div>
              {this.DrawStartAddress()}
            </div>
            <div>
              <h4>{this.props.endAddress}</h4>
            </div>
          </div>
          <div  className="flexColumns">
            <div >
              <table>
                <tr>
                  <td>{Language.t("Datum")}: </td><td>{MyTools.FormatUnixTimestamp(this.props.startDate)}</td>
                </tr>
                <tr>
                  <td>{Language.t("Dauer")}</td><td>{MyTools.DrawDuration(this.props.minuteDiff)}</td>
                </tr>
                <tr>
                  <td>{Language.t("km Stand")}: </td><td>{this.GetOdometer()} {Units.km_miles()}</td>
                </tr>
              </table>
            </div>
            <div >
              <table>
                <tr>
                  <td>{Language.t("Verbraucht")}:</td>
                  <td><a href={this.GetAlleVerbraeucheURL()} title={Language.t("Alle Verbräuche - ScanMyTesla")}>{Math.round(this.props.kWh * 10) / 10}kWh {this.GetDurchschnittsverbrauch()}</a></td>
                </tr>
                <tr>
                  <td>{Language.t("TR diff")}: </td><td>{this.GetRangediff()} {Units.km_miles()}</td>
                </tr>
                <tr>
                  <td>{Language.t("SOC")}: </td><td>{this.props.soc}% / {Language.t("SOC diff")}: {this.props.socDiff}%</td>
                </tr>
              </table>
            </div>
            <div >
              <table>
                  {this.DrawVampirDrainRow()}
                  <tr><td>{Language.t("Standby")}: </td><td>{MyTools.DrawDuration(this.props.sleephours)}</td></tr>
              </table>
            </div>
          </div>
        </div>
        <div className="ib2">
          <a href={MyTools.GetUrl(this.props.action, this.props.startDate, this.props.endDate, this.props.carid)}>
            <LazyLoadImage src={this.GetMapImg()} alt="" width="200px" height="150px" />
          </a>
        </div>
      </div>
    );
  }

  DrawVampirDrainRow()
  {
    if (this.props.minuteDiff >= 30)
      return <tr><td>{Language.t("Vampir Drain")}:</td><td><a href={this.GetVampirDrainURL()}>Ø {this.DrawVampirDrain()}W</a></td></tr>;
    else
      return "";
  }

  DrawVampirDrain()
  {
    if (this.props.minuteDiff == 0)
      return 0;
      
    let vampirDrainPerHour = this.props.kWh / this.props.minuteDiff * 60 * 1000;
    return Math.round(vampirDrainPerHour);
  }

  GetHVAC_URL()
  {
    return '/d/dL76LVmRk/hvac-scanmytesla?from=' + this.props.startDate + '&to=' + this.props.endDate + '&var-Car=' + this.props.carid;
  }

  GetAlleVerbraeucheURL()
  {
    return '/d/eMEMotRgk/alle-verbrauche-scanmytesla?from=' + this.props.startDate + '&to=' + this.props.endDate + '&var-Car=' + this.props.carid;
  }

  GetVampirDrainURL()
  {
    return '/d/zhHx2Fggk/vampir-drain?var-Car=' + this.props.carid;
  }

  GetDurchschnittsverbrauch()
  {
    if (this.props.action === "Driving")
    {
      let avgconsumption = this.props.kWh / Units.Distance(this.props.odometerEnd - this.props.odometer) * 100;
      let y = Math.round(avgconsumption * 10) / 10;
      
      return "/ Ø " + y +" kWh";
    }
    return "";
  }

  DrawChargingCosts()
  {
    if (this.props.action === "Charging")
    {
      let ccurl = Settings.adminurl+"/chargingcost.php?id="+this.props.csid;

      if (this.props.cost)
        return <a href={ccurl} title="Set Charging Cost">{MyTools.DrawLabelIfnotNullWithRound(Language.t("Cost")+": ", this.props.cost, "Eur", 100)}</a>;
      else  
        return <a href={ccurl} title="Set Charging Cost">Set Costs</a>;
    }
    return "";
  }

  GetOutsideTemperature()
  {
    if (this.props.outsideTempAvg)
    {
      let x = Units.Temperature(this.props.outsideTempAvg);
      let y = Math.round(x * 10) / 10;
      return y;
    }
    else
    return null;
  }

  GetRangediff()
  {
    let x = Units.Distance(this.props.rangeDiff);
    let y = Math.round(x * 10) / 10;
    return y;
  }

  GetOdometer()
  {
    let x = Units.Distance(this.props.odometer);
    let y = Math.round(x * 10) / 10;
    return y;
  }

  GetDistance()
  {
    let x = Units.Distance(this.props.odometerEnd - this.props.odometer);
    let y = Math.round(x * 10) / 10;
    return y;
  }

  GetMapImg()
  {
    let lat = Math.round(this.props.startLat * 10000) / 10000;
    let lng = Math.round(this.props.startLng * 10000) / 10000;

    return "public/plugins/teslalogger-timeline-panel/maps/P-" + lat + "-"+ lng +".png"; 
  }


  DrawStartAddress()
  {
    let url = Settings.adminurl+"/geoadd.php?lat="+this.props.startLat+"&lng="+this.props.startLng;

    if (this.props.endAddress.length > 0)
      return <h4><a href={url} title="add geofence">{this.props.startAddress}</a> ➔</h4>;

    return <h4><a href={url} title="add geofence">{this.props.startAddress}</a></h4>;
  }
}
