import React, { Component } from 'react';
import MyTools from './MyTools'
import Language from './Language'
import Units from './Settings'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Settings from './Settings';

export class Driving extends Component {
  props: any;
  static setWidthDone = false;  

  componentDidMount() {
    if (!Driving.setWidthDone)
    {
      Driving.setWidthDone = true;
      
      this.SetMaxWidthOfAction();
      this.SetMaxWidthOfFirstFlexColumn();
    }
  }

  SetMaxWidthOfAction()
  {
    let maxWidth = 0;
      const tempClass = document.querySelectorAll<HTMLElement>(".Action");
      tempClass.forEach(r => maxWidth = Math.max(maxWidth, r.clientWidth));

      let w = String(maxWidth+10)+"px";
      Array.from(tempClass).forEach(r => r.style.width = w);
      console.log("Action Width=" + maxWidth);
  }

  SetMaxWidthOfFirstFlexColumn()
  {
    let pc = document.querySelector(".panel-content");
    if ((pc?.clientWidth ?? Number.MAX_VALUE) < 650)
    {
      let maxWidth = 0;
      const tempClass = document.querySelectorAll<HTMLElement>(".flexColumns > div > table > tr > td:first-child");
      tempClass.forEach(r => maxWidth = Math.max(maxWidth, r.clientWidth));
      
      let w = String(maxWidth)+"px";
      tempClass.forEach(r => r.style.width = w);
      
      console.log("FirstFlexColumn Width=" + maxWidth);
    }
  }


  render() {
    return (
      <div className={MyTools.GetDivClassName(this.props.action, this.props.isLight)}>
        <div className="ib1">
          <div className="flexAddress">
            <div className="Action">
              <h1>
                <a href={MyTools.GetUrl(this.props.action, this.props.startDate, this.props.endDate, this.props.carid)} title={Language.t("Verbrauch")}>{Language.t(this.props.action)}</a>
              </h1>
            </div>
            <div>
              {this.DrawStartAddress()}
            </div>
            <div>
              <h4>{this.props.endAddress}</h4>
            </div>
          </div>
          <div  className="flexColumns">
            <div >
              <table>
                <tr>
                  <td>{Language.t("Datum")}: </td><td>{MyTools.FormatUnixTimestamp(this.props.startDate)}</td>
                </tr>
                <tr>
                  <td>{Language.t("Dauer")}</td><td>{MyTools.DrawDuration(this.props.minuteDiff)}</td>
                </tr>
                <tr>
                  <td>{Language.t("km Stand")}: </td><td>{this.GetOdometer()} {Units.km_miles()}</td>
                </tr>
                {this.DrawDistance()}
              </table>
            </div>
            <div >
              <table>
                <tr>
                  <td>{this.props.action === "Charging" ? Language.t("Geladen") : Language.t("Verbraucht")}:</td>
                  <td><a href={this.GetAlleVerbraeucheURL()} title={Language.t("Alle Verbräuche - ScanMyTesla")}>{Math.round(this.props.kWh * 10) / 10}kWh {this.GetDurchschnittsverbrauch()}</a></td>
                </tr>
                <tr>
                  <td>{Language.t("TR diff")}: </td><td>{this.GetRangediff()} {Units.km_miles()}</td>
                </tr>
                <tr>
                  <td>{Language.t("SOC")}: </td><td>{this.props.soc}% / {Language.t("SOC diff")}: {this.props.socDiff}%</td>
                </tr>
              </table>
            </div>
            <div >
                <table>
                    <tr>
                        <td>{Language.t("Ø Temperatur")}:</td>
                        <td><a href={this.GetHVAC_URL()} title={Language.t("HVAC - ScanMyTesla")}> {this.GetOutsideTemperature()} {Units.celsius_farenheit()}</a></td>
                    </tr>
                    <tr>
                        <td>{Language.t("Max Speed")}:</td>
                        <td>{Settings.Distance(this.props.speed_max)} {Settings.kmh_mph()}</td>
                    </tr>
                    <tr>
                        <td>{Language.t("Ø Speed")}</td>
                        <td>{Settings.Distance(this.GetAvgSpeed())} {Settings.kmh_mph()}</td>
                    </tr>
                </table>
              <br />               
            </div>
          </div>
        </div>
        <div className="ib2">
          <a href={MyTools.GetUrl(this.props.action, this.props.startDate, this.props.endDate, this.props.carid)}>
            <LazyLoadImage src={this.GetMapImg()} alt="" width="200px" height="150px"/>
          </a>
        </div>
      </div>
    );
  }

  GetAvgSpeed()
  {
      let avg = (this.props.odometerEnd - this.props.odometer) / this.props.minuteDiff * 60;
      let r = Math.round(avg * 10) / 10;
      return r;
  }

  GetHVAC_URL()
  {
    return 'd/dL76LVmRk/hvac-scanmytesla?from=' + this.props.startDate + '&to=' + this.props.endDate + '&var-Car=' + this.props.carid;
  }

  GetAlleVerbraeucheURL()
  {
    return 'd/eMEMotRgk/alle-verbrauche-scanmytesla?from=' + this.props.startDate + '&to=' + this.props.endDate + '&var-Car=' + this.props.carid;
  }

  DrawDistance()
  {
    if (this.props.action === "Driving")
      return <tr><td>{Language.t("Distance")}:</td><td>{this.GetDistance()} {Units.km_miles()}</td></tr>;
    return "";
  }

  GetDurchschnittsverbrauch()
  {
    if (this.props.action === "Driving")
    {
      let avgconsumption = this.props.kWh / Units.Distance(this.props.odometerEnd - this.props.odometer) * 100;
      let y = Math.round(avgconsumption * 10) / 10;
      
      return "/ Ø " + y +" kWh";
    }
    return "";
  }

  DrawChargingCosts()
  {
    if (this.props.action === "Charging")
    {
      let ccurl = Settings.adminurl+"/?id="+this.props.csid;

      if (this.props.cost)
        return <a href={ccurl} title="Set Charging Cost">{MyTools.DrawLabelIfnotNullWithRound(Language.t("Cost")+": ", this.props.cost, "Eur", 100)}</a>;
      else  
        return <a href={ccurl} title="Set Charging Cost">Set Costs</a>;
    }
    return "";
  }

  GetOutsideTemperature()
  {
    if (this.props.outsideTempAvg)
    {
      let x = Units.Temperature(this.props.outsideTempAvg);
      let y = Math.round(x * 10) / 10;
      return y;
    }
    else
    return null;
  }

  GetRangediff()
  {
    let x = Units.Distance(this.props.rangeDiff);
    let y = Math.round(x * 10) / 10;
    return y;
  }

  GetOdometer()
  {
    let x = Units.Distance(this.props.odometer);
    let y = Math.round(x * 10) / 10;
    return y;
  }

  GetDistance()
  {
    let x = Units.Distance(this.props.odometerEnd - this.props.odometer);
    let y = Math.round(x * 10) / 10;
    return y;
  }

  GetMapImg()
  {
      return "public/plugins/teslalogger-timeline-panel/maps/T" + this.props.carid+"-" + this.props.startposid + "-" + this.props.endposid+".png";
  }


  DrawStartAddress()
  {
    let url = Settings.adminurl+"/geoadd.php?lat="+this.props.startLat+"&lng="+this.props.startLng;

    if (this.props.endAddress.length > 0)
      return <h4><a href={url} title="add geofence">{this.props.startAddress}</a> ➔</h4>;

    return <h4><a href={url} title="add geofence">{this.props.startAddress}</a></h4>;
  }
}
