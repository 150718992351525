export default class Langugage
{
    static ready: boolean = false;
    static myhash = new Map<string, string>();
    
    
    static t(t: string) : string
    {
        if (!Langugage.ready)
            Langugage.LoadTranslationFile();

        if (this.myhash.has(t))
            return this.myhash.get(t)!;

        return t;
    }

    static LoadTranslationFile()
    {
        Langugage.ready = true;
        // this.myhash.set("Date", "Datum");
        var request = new XMLHttpRequest();
        request.open('GET','public/plugins/teslalogger-timeline-panel/language.txt?_cache=' + new Date().getTime(), false);
        request.send(null);
        if (request.status === 200)
        {
            var responseText = request.responseText;
            var lines = responseText.split("\n");
            lines.forEach(l => 
                {
                    var kva = l.split("=");
                    if (kva.length > 1)
                    {
                        var key = kva[0];
                        var val = kva[1];
                        if (val.endsWith("\r"))
                            val = val.substring(0,val.length - 1);

                        if (val.startsWith("\"") && val.endsWith("\""))
                            val = val.substring(1,val.length-1);

                        this.myhash.set(key, val);
                    }
                });
        }
    }
}