import React, { Component } from 'react';
import MyTools from './MyTools'

export class FwUpdate extends Component {
  props: any;
  render() {
    return (
      <div className={MyTools.GetDivClassName(this.props.action, this.props.isLight)}>
          <div className="ib1">
            <div className="flexAddress">
            <div className="Action">
                <h1>
                <a href={MyTools.GetUrl(this.props.action, this.props.startDate, this.props.endDate, this.props.carid)}>{this.props.action}</a>
                </h1>
            </div>
            <div>
                <h4>{this.props.startAddress}</h4>
            </div>
            </div>
            <div  className="flexColumns">
            <div >
                Date: {MyTools.FormatUnixTimestamp(this.props.startDate)}
                <br />
                <br />
            </div>
            <div></div>
            <div></div>
            </div>
        </div>
      </div>
    );
  }
}
