import React, { Component } from 'react';
import MyTools from './MyTools'
import Language from './Language'
import Units from './Settings'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Settings from './Settings';

export class Charging extends Component {
  props: any; 

  render() {
    return (
      <div className={MyTools.GetDivClassName(this.props.action, this.props.isLight)}>
        <div className="ib1">
          <div className="flexAddress">
            <div className="Action">
              <h1>
                <a href={MyTools.GetUrl(this.props.action, this.props.startDate, this.props.endDate, this.props.carid)} title={Language.t("Verbrauch")}>{Language.t(this.props.action)}</a>
              </h1>
            </div>
            <div>
              {this.DrawStartAddress()}
            </div>
            <div>
              <h4>{this.props.endAddress}</h4>
            </div>
          </div>
          <div  className="flexColumns">
            <div >
              <table>
                <tr>
                  <td>{Language.t("Datum")}: </td><td>{MyTools.FormatUnixTimestamp(this.props.startDate)}</td>
                </tr>
                <tr>
                  <td>{Language.t("Dauer")}</td><td>{MyTools.DrawDuration(this.props.minuteDiff)}</td>
                </tr>
                <tr>
                  <td>{Language.t("km Stand")}: </td><td>{this.GetOdometer()} {Units.km_miles()}</td>
                </tr>
                {this.DrawDistance()}
              </table>
            </div>
            <div >
              <table>
                <tr>
                  <td>{Language.t("Geladen")}:</td>
                  <td><a href={this.GetAlleVerbraeucheURL()} title={Language.t("Alle Verbräuche - ScanMyTesla")}>{Math.round(this.props.kWh * 10) / 10}kWh</a></td>
                </tr>
                <tr>
                  <td>{Language.t("TR diff")}: </td><td>{this.GetRangediff()} {Units.km_miles()}</td>
                </tr>
                <tr>
                  <td>{Language.t("SOC")}: </td><td>{this.props.soc}% / {Language.t("SOC diff")}: {this.props.socDiff}%</td>
                </tr>
              </table>
            </div>
            <div >
              <table>
                <tr>
                  <td>{Language.t("Cost")}:</td><td>{this.DrawChargingCosts()}</td>
                </tr>
                <tr>
                  <td>{Language.t("max kW")}:</td><td>{this.props.max_charger_power} kW</td>
                </tr>
                <tr>
                  <td>{Language.t("Ø kW")}:</td><td>{this.GetAVGChargingkW()} kW</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="ib2">
          <a href={MyTools.GetUrl(this.props.action, this.props.startDate, this.props.endDate, this.props.carid)}>
            <LazyLoadImage src={this.GetMapImg()} alt="" width="200px" height="150px"/>
          </a>
        </div>
      </div>
    );
  }

  GetAVGChargingkW()
  {
    let avg = this.props.kWh / this.props.minuteDiff * 60

    if (avg > this.props.max_charger_power)
        return "";

    if (avg == Infinity)
        return "";

    return Math.round(avg * 10) /10;
  }

  GetHVAC_URL()
  {
    return 'd/dL76LVmRk/hvac-scanmytesla?from=' + this.props.startDate + '&to=' + this.props.endDate + '&var-Car=' + this.props.carid;
  }

  GetAlleVerbraeucheURL()
  {
    return 'd/eMEMotRgk/alle-verbrauche-scanmytesla?from=' + this.props.startDate + '&to=' + this.props.endDate + '&var-Car=' + this.props.carid;
  }

  DrawDistance()
  {
    if (this.props.action === "Driving")
      return <tr><td>{Language.t("Distance")}:</td><td>{this.GetDistance()} {Units.km_miles()}</td></tr>;
    return "";
  }

  DrawChargingCosts()
  {
    if (this.props.action === "Charging")
    {
      let ccurl = Settings.adminurl+"/chargingcost.php?id="+this.props.csid;

      if (this.props.cost || this.props.cost == 0)
        return <a href={ccurl} title="Set Charging Cost">{this.props.cost.toFixed(2)} {this.props.currency ? this.props.currency : "Eur"}</a>;
      else  
        return <a href={ccurl} title="Set Charging Cost">{Language.t("Set Costs")}</a>;
    }
    return "";
  }

  GetOutsideTemperature()
  {
    if (this.props.outsideTempAvg)
    {
      let x = Units.Temperature(this.props.outsideTempAvg);
      let y = Math.round(x * 10) / 10;
      return y;
    }
    else
    return null;
  }

  GetRangediff()
  {
    let x = Units.Distance(this.props.rangeDiff);
    let y = Math.round(x * 10) / 10;
    return y;
  }

  GetOdometer()
  {
    let x = Units.Distance(this.props.odometer);
    let y = Math.round(x * 10) / 10;
    return y;
  }

  GetDistance()
  {
    let x = Units.Distance(this.props.odometerEnd - this.props.odometer);
    let y = Math.round(x * 10) / 10;
    return y;
  }

  GetMapImg()
  {
    
    let lat = Math.round(this.props.startLat * 10000) / 10000;
    let lng = Math.round(this.props.startLng * 10000) / 10000;

    return "public/plugins/teslalogger-timeline-panel/maps/C-" + lat + "-"+ lng +".png"; 
  }


  DrawStartAddress()
  {
    let url = Settings.adminurl+"/geoadd.php?lat="+this.props.startLat+"&lng="+this.props.startLng;
    return <h4><a href={url} title="add geofence">{this.props.startAddress}</a></h4>;
  }
}
