export default class MyTools {
    static GetUrl(action : string, startDate : number, endDate : number, carid : number) {
        if (action === 'Driving' || action === 'Parking') {
          return 'd/zm7wN6Zgz/verbrauch?from=' + startDate + '&to=' + endDate + '&var-Car=' + carid;
        } else if (action === 'Charging') {
          return 'd/BHhxFeZRz/laden?orgId=1&from=' + startDate + '&to=' + endDate + '&var-Car=' + carid;
        } 
    
        return '#';
      }

    static GetDivClassName(action : string, isLight : boolean) {
        let bgcolor = isLight ? 'cbl' : 'cbd';

        if (action === 'Driving') {
          return 'cb blgr ' + bgcolor;
        } else if (action === 'Parking') {
          return 'cb blbl ' + bgcolor;
        } else if (action === 'Charging') {
          return 'cb blge ' + bgcolor;
        } else if (action === 'Missing') {
          return 'cb blrt ' + bgcolor;
        } else if (action === 'Update') {
          return 'cb blpr ' + bgcolor;
        }
    
        return 'cb';
      }

    static FormatUnixTimestamp(timestamp: number) {
        let d = new Date(timestamp);
        return d.toLocaleString();
    }
    
    static DrawLabelIfnotNull(label: string, n: number, suffix: string) {
        if (n != null) {
            return label + n + suffix;
        }

        return '';
    }
    
    static DrawLabelIfnotNullWithRound(label: string, n: number | null, suffix: string, round : number) {
        if (n != null) {
            let t = Math.round(n * round) / round;
            return label + t + suffix;
        }

        return '';
    }

    static DrawDuration(minutes: number)
    {
      let ret = "";
      
      var num = minutes;
      var days = num / (60*24);
      var rDays = Math.floor(days);

      if (rDays > 0)
        ret = rDays +"d ";

      var hours = ((num - (rDays*60*24)) / 60);
      var rhours = Math.floor(hours);

      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);

      return ret + rhours + "h " + rminutes + "m" ;
    }
}
