import React from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
//import { css, cx } from 'emotion';
//import { stylesFactory, useTheme } from '@grafana/ui';
import { Parking } from './Parking';
import { Driving } from './Driving';
import { Missing } from './Missing';
import { Charging } from './Charging';
import { FwUpdate } from './FwUpdate';
import { useTheme } from '@grafana/ui';
import Settings from './Settings';

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, replaceVariables}) => {
  const theme = useTheme();
  // const styles = getStyles();
  const isLight = theme.isLight;
  Settings.LoadSettingsFile();

  const carid = replaceVariables("$Car");
  console.log("Carid: " + carid);

  if (data === undefined || data.series.length === 0 || data.series[0].length === 0)
    return <div className="fw"><div className="Action"><h1>No Data!</h1></div></div>;

  const f = data.series[0].fields[1];
  // const s = data.series[0];
  const l = f.values.length;
  const items = [];

  const cAction = getColumn(data,'Action');
  const cStartDate = getColumn(data,'StartDate');
  const cEndDate = getColumn(data,'EndDate');
  const cStart_address = getColumn(data,'Start_address');
  const cOdometer = getColumn(data,'odometer');
  const cDistance = getColumn(data,'distance');
  const cMinuteDiff = getColumn(data,'MinuteDiff');
  const cEnd_address = getColumn(data,'End_address');
  const ckWh = getColumn(data,'kWh');
  const cRangeDiff = getColumn(data,'RangeDiff');
  const cSOC = getColumn(data,'SOC');
  const cSOC_Diff = getColumn(data,'SOC_Diff');
  const coutside_temp_avg = getColumn(data,'outside_temp_avg');
  const cstartposid = getColumn(data,'startposid');
  const cendposid = getColumn(data,'endposid');
  const cStartLat = getColumn(data,'StartLat');
  const cStartLng = getColumn(data,'StartLng');
  const codometerEnd = getColumn(data,'odometerEnd');
  const cspeed_max = getColumn(data,'speed_max');
  const cmax_charger_power = getColumn(data,'max_charger_power');
  const csleephours = getColumn(data,'sleephours');
  const ccost = getColumn(data,'cost');
  const ccsid = getColumn(data,'csid');
  const ccurrency = getColumn(data,'currency');

  for (let i = 0; i < l; i++) {
    //console.log('Index: ' + i + ' value: ' + f.values.get(i));
    //console.log('test: ' + getData(i, 'Action', data));
    //items.push(<Parking action={s.fields[0].values.get(i)} time={s.fields[0].values.get(i)} />);

    let action = cAction.values.get(i);

    if (action === "Missing")
    {
      items.push(
      <Missing
          action={action}
          startDate={cStartDate.values.get(i)}
          endDate={cEndDate.values.get(i)}
          startAddress={cStart_address.values.get(i)}
          odometer={cOdometer.values.get(i)}
          distance={cDistance.values.get(i)}
          carid={carid}
          isLight={isLight}
        />);

    } else if (action === "Update")
    {
      items.push(
      <FwUpdate
          action={action}
          startDate={cStartDate.values.get(i)}
          endDate={cEndDate.values.get(i)}
          startAddress={cStart_address.values.get(i)}
          odometer={cOdometer.values.get(i)}
          distance={cDistance.values.get(i)}
          carid={carid}
          isLight={isLight}
        />);

    }
    else if (action === "Parking")
    {
      items.push(
        <Parking
          action={action}
          startDate={cStartDate.values.get(i)}
          endDate={cEndDate.values.get(i)}
          startAddress={cStart_address.values.get(i)}
          odometer={cOdometer.values.get(i)}
          distance={cDistance.values.get(i)}          
          minuteDiff={cMinuteDiff.values.get(i)}
          endAddress={cEnd_address.values.get(i)}
          kWh={ckWh.values.get(i)}
          rangeDiff={cRangeDiff.values.get(i)}
          soc={cSOC.values.get(i)}
          socDiff={cSOC_Diff.values.get(i)}
          outsideTempAvg={coutside_temp_avg.values.get(i)}
          startposid={cstartposid.values.get(i)}
          endposid={cendposid.values.get(i)}
          carid={carid}
          isLight={isLight}
          startLat={cStartLat.values.get(i)}
          startLng={cStartLng.values.get(i)}
          odometerEnd={codometerEnd.values.get(i)}
          speed_max={cspeed_max.values.get(i)}
          max_charger_power={cmax_charger_power.values.get(i)}
          sleephours={csleephours.values.get(i)}
        />
      );
    }
    else if (action === "Charging")
    {
      items.push(
        <Charging
          action={action}
          startDate={cStartDate.values.get(i)}
          endDate={cEndDate.values.get(i)}
          minuteDiff={cMinuteDiff.values.get(i)}
          startAddress={cStart_address.values.get(i)}
          kWh={ckWh.values.get(i)}
          rangeDiff={cRangeDiff.values.get(i)}
          odometer={cOdometer.values.get(i)}
          soc={cSOC.values.get(i)}
          socDiff={cSOC_Diff.values.get(i)}
          startposid={cstartposid.values.get(i)}
          carid={carid}
          isLight={isLight}
          startLat={cStartLat.values.get(i)}
          startLng={cStartLng.values.get(i)}
          cost={ccost.values.get(i)}
          csid={ccsid.values.get(i)}
          currency={ccurrency.values.get(i)}
          max_charger_power={cmax_charger_power.values.get(i)}
        />
      );
    }
    else
    {
      items.push(
        <Driving
          action={action}
          startDate={cStartDate.values.get(i)}
          endDate={cEndDate.values.get(i)}
          minuteDiff={cMinuteDiff.values.get(i)}
          startAddress={cStart_address.values.get(i)}
          endAddress={cEnd_address.values.get(i)}
          rangeDiff={cRangeDiff.values.get(i)}
          odometer={cOdometer.values.get(i)}
          soc={cSOC.values.get(i)}
          socDiff={cSOC_Diff.values.get(i)}
          outsideTempAvg={coutside_temp_avg.values.get(i)}
          startposid={cstartposid.values.get(i)}
          endposid={cendposid.values.get(i)}
          carid={carid}
          isLight={isLight}
          startLat={cStartLat.values.get(i)}
          startLng={cStartLng.values.get(i)}
          odometerEnd={codometerEnd.values.get(i)}
          kWh={ckWh.values.get(i)}
          speed_max={cspeed_max.values.get(i)}
        />
      );
    }
  }

  return <div className="fw">
    <div className="card-list">
    {items}</div>
    </div>;
};

function getColumn(data: any, column: string) {
  const f = data.series[0].fields;
  const ret = f.find((x: any) => x.name === column);

  if (ret === undefined)
    console.log("Column " + column + " not found!!!");

  return ret;
}
